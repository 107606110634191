import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FULLSTAR_EMBED_ID } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterEventService implements OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(private router: Router, private titleService: Title) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  // 返信一覧表示判定。対象ユーザーが遷移しようとした際、案件一覧へリダイレクト
  private redirectTargetUserToTop(): boolean {
    const restrictedUserCodes = ['sales.all.technocore'];
    const account = JSON.parse(localStorage.getItem('account'));
    if (account && restrictedUserCodes.includes(account.user_code)) {
      this.router.navigate(['/projects']);
      return true;
    }
    return false;
  }

  public changeTabName(): void {
    const changeTabNameSub = this.router.events.subscribe((nav: any) => {
      if (nav instanceof NavigationEnd) {
        if (nav.url.match(/login/)) {
          this.titleService.setTitle(this.createTabName('ログイン'));
        } else if (nav.url.match(/matching\/projects/)) {
          if (nav.url.match(/matching\/projects\//)) {
            this.titleService.setTitle(this.createTabName('案件詳細'));
          } else {
            this.titleService.setTitle(this.createTabName('案件一覧'));
          }
        } else if (nav.url.match(/matching\/workers/)) {
          if (nav.url.match(/matching\/workers\//)) {
            this.titleService.setTitle(this.createTabName('人材詳細'));
          } else {
            this.titleService.setTitle(this.createTabName('人材一覧'));
          }
        } else if (nav.url.match(/replies/)) {
          // 特定のuser_codeの場合はリダイレクト
          if (this.redirectTargetUserToTop()) {
            return;
          }
          this.titleService.setTitle(this.createTabName('返信一覧'));
        } else if (nav.url.match(/matching\/auto/)) {
          this.titleService.setTitle(this.createTabName('おすすめ'));
        } else if (nav.url.match(/offer/)) {
          this.titleService.setTitle(this.createTabName('提案管理'));
        } else if (nav.url.match(/mail-register/)) {
          this.titleService.setTitle(this.createTabName('案件人材管理'));
        } else if (nav.url.match(/report/)) {
          this.titleService.setTitle(this.createTabName('レポート(β)'));
        } else if (nav.url.match(/account\/setting/)) {
          this.titleService.setTitle(this.createTabName('設定'));
        } else if (nav.url.match(/registration/)) {
          this.titleService.setTitle(this.createTabName('ユーザー登録'));
        } else if (nav.url.match(/account\/setting\/notification/)) {
          this.titleService.setTitle(this.createTabName('通知設定'));
        } else if (nav.url.match(/account\/setting\/matching-condition/)) {
          this.titleService.setTitle(this.createTabName('マッチング条件設定'));
        } else if (nav.url.match(/account\/setting\/business/)) {
          this.titleService.setTitle(this.createTabName('BP設定'));
        } else if (nav.url.match(/account\/password\/change/)) {
          this.titleService.setTitle(this.createTabName('パスワード変更'));
        } else if (nav.url.match(/account\/password\/reset\/mail/)) {
          this.titleService.setTitle(this.createTabName('パスワード再発行'));
        } else if (nav.url.match(/privacy-policy-component/)) {
          this.titleService.setTitle(
            this.createTabName('プライバシーポリシー')
          );
        } else if (nav.url.match(/terms-of-service/)) {
          this.titleService.setTitle(this.createTabName('利用規約'));
        }
      }
    });
    this.subscriptions.add(changeTabNameSub);
  }

  public executeFullstarScript(): void {
    const executeFullstarScriptSub = this.router.events.subscribe((nav: any) => {
      if (nav instanceof NavigationEnd) {
        const div = document.getElementById('fullstar_script');
        // fullstar_scriptの中身が重複されないための事前クリア処理
        while (div.firstChild) {
          div.removeChild(div.firstChild);
        }

        // ページ遷移成功の場合はFullstarスクリプトを実行する（要ログイン画面のみ）
        if (!!localStorage.account && !nav.url.match(/login/)) {
          const account = JSON.parse(localStorage.account);

          // 五十嵐様のマスターアカウント(igarashi.keita.*@riff.co.jp)の場合は、Fullstarスクリプトを実行しない
          if (/^igarashi\.keita\..+@riff\.co\.jp$/.test(account.email)) {
            return;
          }

          // ---- Fullstar(フルスタ)埋め込みコード start ----
          const fullstar_script = document.createElement('script');
          var param_user_uuid = account.id; // ログインユーザーのIDなど
          var param_user_name = account.name; //ログインユーザーの名前
          var param_user_company = account.company_name; //ログインユーザーの会社名
          var param_user_email = account.email; //ログインユーザーのメールアドレス
          var param_user_plan = account.user_code; //ログインユーザーのプラン(FullStarに合わせPlanとしているが、渡すパラメータはuser_code)
          fullstar_script.innerHTML = `
            fs.clear();fs.show("${FULLSTAR_EMBED_ID}", "${param_user_uuid}", {
              company_name: "${param_user_company}",
              user_name: "${param_user_name}",
              user_email: "${param_user_email}",
              plan_name: "${param_user_plan}"
            });
            fs.tracking();
            fs.survey();
            fs.guide();
            fs.checklist();
            fs.tooltip();
          `;
          div.insertAdjacentElement('afterbegin', fullstar_script);
          // ---- Fullstar(フルスタ)埋め込みコード end ----
        }
      }
    });
    this.subscriptions.add(executeFullstarScriptSub);
  }

  private createTabName(page: string) {
    return `${page} ｜ Qoala`;
  }
}
